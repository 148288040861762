<script setup>
const props = defineProps({
  errorMessage: {
    type: String,
    default: ''
  },
  isDisabled: {
    type: Boolean,
    default: false
  }
});
const email = defineModel('email')
const password = defineModel('password')
</script>

<template>
  <v-sheet min-height="350">
    <h1 class="mt-4 mb-6">Register</h1>
    <div class="mt-3 mb-6">
      Create an account to start your adventure
    </div>
    <v-sheet width="300" class="mx-auto mt-4">
      <v-text-field
        label="Email"
        v-model="email"
        :disabled="props.isDisabled"
        :error-messages="props.errorMessage"
      ></v-text-field>
      <v-text-field
        label="Password"
        type="password"
        v-model="password"
        :disabled="props.isDisabled"
        :error-messages="props.errorMessage"
      ></v-text-field>
      <div class="mt-8 mb-6">
        <div>Already have an account?</div>
        <v-btn
          class="me-4"
          variant="text"
          prepend-icon="mdi-login"
          :to="{ name: 'Login' }"
        > Login </v-btn>
      </div>
    </v-sheet>
  </v-sheet>
</template>