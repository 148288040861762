import { createApp } from 'vue'
import { initializeApp } from 'firebase/app'
import { getAnalytics } from 'firebase/analytics'
import { getAuth, onAuthStateChanged, onIdTokenChanged } from 'firebase/auth'
import App from './App.vue'
import router from './router'
import store from './store'
import { configureApi } from '@/utils/api.js'

// Import global CSS
import '@/assets/global.css' // Adjust the path if necessary

// Vuetify
import 'vuetify/styles'
import { createVuetify } from 'vuetify'
import * as components from 'vuetify/components'
import * as directives from 'vuetify/directives'

import { fa } from "vuetify/iconsets/fa";
import { aliases, mdi } from 'vuetify/iconsets/mdi'
import "@mdi/font/css/materialdesignicons.css"
import "@fortawesome/fontawesome-free/css/all.css"

import {brightOrangeTheme} from '@/plugins/vuetify'
const vuetify = createVuetify({
  components,
  directives,
  icons: {
    defaultSet: 'mdi',
    aliases,
    sets: {
      mdi,
      fa,
    },
  },
  theme: {
    defaultTheme: 'brightOrangeTheme',
    themes: {
      brightOrangeTheme,
    },
  },
})

// uncomment to debug store in console
// window.store = store 

// TODO: Add SDKs for Firebase products that you want to use
// https://firebase.google.com/docs/web/setup#available-libraries

// Your web app's Firebase configuration
// For Firebase JS SDK v7.20.0 and later, measurementId is optional
const firebaseConfig = {
  apiKey: process.env.VUE_APP_FIREBASE_API_KEY,
  authDomain: process.env.VUE_APP_FIREBASE_AUTH_DOMAIN,
  projectId: process.env.VUE_APP_FIREBASE_PROJECT_ID,
  storageBucket: process.env.VUE_APP_FIREBASE_STORAGE_BUCKET,
  messagingSenderId: process.env.VUE_APP_FIREBASE_MESSAGING_SENDER_ID,
  appId: process.env.VUE_APP_FIREBASE_APP_ID,
  measurementId: process.env.VUE_APP_FIREBASE_MEASUREMENT_ID
};

const firebaseApp = initializeApp(firebaseConfig)
getAnalytics(firebaseApp)
const auth = getAuth()

configureApi(store.state.token)

let vueApp = undefined
onAuthStateChanged(auth, async (user) => {
  // TODO: refactor to move to seperate class
  if (user && !store.user) {
    store.dispatch('setUser', user.uid)
    const idTokenResult = await user.getIdTokenResult(true);
    const isRegistrationComplete = idTokenResult.claims.is_registration_complete || false;
    store.dispatch('setToken', idTokenResult.token)
    store.dispatch('setIsRegistrationComplete', isRegistrationComplete)
    configureApi(idTokenResult.token)
  }

  if (!vueApp) {
    // wait for firebase before creating app
    vueApp = createApp(App)
      .use(router)
      .use(store)
      .use(vuetify)
      .mount('#app')
  }
})

onIdTokenChanged(auth, async (user) => {
  if (user) {
    const idTokenResult = await user.getIdTokenResult(true)
    const isRegistrationComplete = idTokenResult.claims.is_registration_complete || false
    store.dispatch('setToken', idTokenResult.token)
    store.dispatch('setIsRegistrationComplete', isRegistrationComplete)
    configureApi(idTokenResult.token)
  }
})
